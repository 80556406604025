import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import type { ButtonProps } from "@aviary/components/Button/Button.types";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { useDropdownContext } from "../../DropdownContext";

import * as styles from "./DropdownChevron.styles";

interface Props extends ButtonProps {
  /**
   *  Optional custom icon to be used in place of chevron
   */
  customIcon?: IconDefinition;
}

const DropdownChevron = ({ customIcon }: Props) => {
  const { isDropdownOpen } = useDropdownContext();

  const conditionalIcon = [styles.icon, isDropdownOpen && !customIcon && styles.rotatedIcon];

  return (
    <span>
      <FontAwesomeIcon size="sm" icon={customIcon || faChevronDown} css={conditionalIcon} />
    </span>
  );
};

export { DropdownChevron };
